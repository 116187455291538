body {
  margin: 0;
  padding: 0;
  background-color: antiquewhite;
}

a {
  text-decoration: none;
  color: black;
}

.page-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
}

.RP {
  border-radius: 59% 41% 68% 32% / 40% 75% 25% 60% ;
}

.name {
  font-family: "Playfair Display", serif;
}

.socials-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.social-item {
  padding: 0.5rem;
  text-decoration: none;
  font-size: 1.5rem;
  opacity: 0.5;
  transition: all 0.1s ease-in-out;
}

.social-item:hover {
  transform: translateY(-5px);
  opacity: 1;
}
